@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0px;
}

body {
  margin: 0;
 
}

@font-face {
  font-family: "General Sans" ;
  src: local("General Sans"), url("./assets/fonts/GeneralSans-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "General Sans" ;
  src: local("General Sans"), url("./assets/fonts/GeneralSans-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "General Sans" ;
  src: local("General Sans"), url("./assets/fonts/GeneralSans-Semibold.woff");
  font-weight: 600;
}
@font-face {
  font-family: "General Sans" ;
  src: local("General Sans"), url("./assets/fonts/GeneralSans-Bold.woff");
  font-weight: 700;
}
